<template>
    <div class="orders">
        <el-row class="order-list-filter">
            <el-form label-width="auto" :inline="true" size="mini">
                <el-col :span="8">
                    <el-form-item label="支付方式:">
                        <el-select placeholder="请选择支付方式" v-model="pagination.payStyle" class="order-input-filter"
                            @change="loadOrder">
                            <el-option v-for="item in orderPayOption" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="制作类型:">
                        <el-select placeholder="请选择制作类型" class="order-input-filter" v-model="pagination.orderType"
                            @change="loadOrder">
                            <el-option v-for="item in orderTypeOption" :key="item.value" :label="item.label"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="条件搜索:">
                        <el-select placeholder="请输入设备名称" class="order-input-filter" prefix-icon="el-icon-search"
                            v-model="pagination.deviceUuid" @change="loadOrder" filterable remote reserve-keyword
                            clearable :remote-method="deviceNameSearch" :loading="loading">
                            <el-option v-for="item in devices" :key="item.deviceUuid" :label="item.deviceName"
                                :value="item.deviceUuid">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="订单编号:">
                        <el-input placeholder="请输入订单编号" class="order-input-filter" clearable
                            v-model="pagination.orderUuid" @change="loadOrder" prefix-icon="el-icon-search"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="制作时间:">
                        <el-date-picker style="width: 320px;" @change="loadOrder" v-model="makeTimeRange"
                            type="datetimerange" :picker-options="pickerOptions" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期">
                        </el-date-picker>

                    </el-form-item>
                </el-col>

                <el-col :span="4">
                    <el-button type="primary" size="small" @click="exportOrder">导出</el-button>
                </el-col>
            </el-form>
        </el-row>

        <div class="order-data-list">
            <el-table :data="orders">
                <empty slot="empty"></empty>
                <el-table-column label="订单编号" align="center" prop="orderUuid" min-width="240" fixed></el-table-column>
                <el-table-column label="设备名称" align="center" prop="saleName" min-width="130"></el-table-column>
                <el-table-column label="饮品名称/杯量" align="center" prop="name" min-width="160">
                    <template slot-scope="scope">
                        <div class="user-info">
                            <span>
                                {{ scope.row.sourceName }}/{{ scope.row.remark }}
                            </span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="支付金额" align="center">
                    <template slot-scope="scope">
                        <span>{{ (parseInt(scope.row.money) / 100).toFixed(2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="订单来源" align="center">
                    <template slot-scope="scope">
                        <div class="user-info">
                            <span :class="[{ notPay: scope.row.payStyle == 1 }, { aliPay: scope.row.payStyle == 2 },
            { wechatPay: scope.row.payStyle == 3 }]">
                                {{ scope.row.payStyle | filterPay }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="订单类型" align="center">
                    <template slot-scope="scope">
                        <div class="user-info">
                            <span :class="[{ testPayMake: scope.row.orderType == 1 }, { notPayMake: scope.row.orderType == 2 },
            { payMake: scope.row.orderType == 3 }]">
                                {{ scope.row.orderType | filterMakeStyle }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="订单状态" align="center">
                    <template slot-scope="scope">
                        <div class="user-info">
                            <span :class="[{ offline: scope.row.status == 0 }, { fault: scope.row.status == 2 },
            { normal: scope.row.status == 1 }]">
                                <span class="circle" v-show="scope.row.status == 1"></span> {{ scope.row.status |
                filterOrderStatus }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="退款状态" align="center">
                    <template slot-scope="scope">
                        <div class="user-info">
                            <span :class="[{ offline: scope.row.payStatus == 0 },
            { fault: scope.row.payStatus == 1 },
            { normal: scope.row.payStatus == 2 }]">
                                <span class="circle" v-show="scope.row.payStatus == 1"></span>
                                {{ scope.row.payStatus | filterReturnStatus }}
                            </span>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="订单时间" align="center" min-width="140">
                    <template slot-scope="scope">
                        <span>{{ new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss') }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="80" fixed="right">
                    <template slot-scope="scope">

                        <span @click="handleRefund(scope.row, scope.$index)" class="order-edit order"
                            v-if="scope.row.orderType == 3" :class="{ disable: scope.row.payStatus == 2 }">
                            <i class="iconfont icontuikuan"></i> 退款</span>
                        <span class="order-edit order disable" v-else><i class="iconfont icontuikuan"></i> 退款</span>
                        <!--                        <span @click="handleEdit(scope.row, scope.$index)" class="order-delete order">-->
                        <!--                            <i class="iconfont iconshanchu"></i>  删除</span>-->
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-pagination @current-change="handleCurrentChange" class="pagination" :current-page="pagination.curPage"
            :page-size="pagination.limit" layout="total, prev, pager, next, jumper" :total="pagination.total">
        </el-pagination>
    </div>

</template>

<script>
import { orderUnion, orderList, orderRefund, orderExport, orderQuery } from '@/api/collection/order'
import { deviceSearch } from '@/api/equipment/device'

export default {
    name: '',
    components: {},
    activated: function () {
        this.loadOrder()
    },
    data: function () {
        return {
            orders: [],
            pickerOptions: {

                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setHours(0, 0, 0, 0)
                        end.setHours(23, 59, 59, 0)
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24);
                        start.setHours(0, 0, 0, 0)
                        end.setTime(end.getTime() - 3600 * 1000 * 24);
                        end.setHours(23, 59, 59, 0)

                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            orderPayOption: [
                { label: '所有支付', value: 0 },
                { label: '无支付', value: 1 },
                { label: '支付宝', value: 2 },
                { label: '微信支付', value: 3 },
            ],
            orderTypeOption: [
                { label: '所有订单', value: 0 },
                { label: '测试订单', value: 1 },
                { label: '无支付订单', value: 2 },
                { label: '支付订单', value: 3 },
                { label: '租赁套餐', value: 4 },
                { label: '营销策划', value: 5 },
            ],
            pagination: {
                startAt: 0,
                endAt: 0,
                orderType: 0,
                payStyle: 0,
                total: 0,
                deviceUuid: '',
                orderUuid: '',
                current: 0,
                start: 0,
                limit: 10,
            },
            makeTimeRange: [],
            loading: false,
            devices: []

        }
    },
    filters: {
        filterReturnStatus(returnStatus) {
            switch (returnStatus) {
                case 0:
                case 1:
                    return '未退款'
                case 2:
                    return '已退款'
            }
        },
        filterOrderStatus(status) {
            switch (status) {
                case 0:
                    return '制作中'
                case 2:
                    return '制作失败'
                case 1:
                    return '成功'
            }
        },
        filterSize(size) {
            switch (size) {

                case 1:
                    return '中杯'
                case 2:
                    return '大杯'
                default:
                    return '小杯'
            }
        },
        filterPay(style) {
            switch (style) {
                case 1:
                    return '无支付'
                case 2:
                    return '支付宝'
                case 3:
                    return '微信支付'
            }
        },

        filterMakeStyle(style) {
            switch (style) {
                case 1:
                    return '测试'
                case 2:
                    return '无支付'
                case 3:
                    return '支付'
                case 4:
                    return '套餐'
                case 5:
                    return '营销'
            }
        }
    },
    methods: {
        handleCurrentChange(value) {
            this.pagination.curPage = value
            this.pagination.start = (value - 1) * this.pagination.limit
            if (this.makeTimeRange.length > 1) {
                this.pagination.startAt = parseInt(this.makeTimeRange[0] / 1000)
                this.pagination.endAt = parseInt(this.makeTimeRange[1] / 1000)
            } else {
                this.pagination.startAt = 0
                this.pagination.endAt = 0
            }

            orderList(this.pagination).then(res => {
                this.orders = res.data || []
            })
        },
        exportOrder() {
            let vue = this
            this.pagination.curPage = 1
            this.pagination.start = 0
            if (this.makeTimeRange.length > 1) {
                this.pagination.startAt = parseInt(this.makeTimeRange[0] / 1000)
                this.pagination.endAt = parseInt(this.makeTimeRange[1] / 1000)
            } else {
                this.pagination.startAt = 0
                this.pagination.endAt = 0
            }
            orderExport(this.pagination).then(res => {
                const ts = setInterval(function () {
                    orderQuery({ uuid: res.data }).then(res => {
                        if (res.data !== '') {
                            window.open(res.data)
                            clearInterval(ts)
                        }

                    })
                }, 1000)
            })
        },
        loadOrder() {
            let vue = this
            this.pagination.curPage = 1
            this.pagination.start = 0
            if (this.makeTimeRange.length > 1) {
                this.pagination.startAt = parseInt(this.makeTimeRange[0] / 1000)
                this.pagination.endAt = parseInt(this.makeTimeRange[1] / 1000)
            } else {
                this.pagination.startAt = 0
                this.pagination.endAt = 0
            }
            orderUnion(this.pagination).then(res => {
                vue.orders = res.data.list || []
                vue.pagination.total = parseInt(res.data.total)
            })
        },
        deviceNameSearch(query) {
            if (query !== '') {
                this.loading = true;
                deviceSearch({ name: query }).then(res => {
                    this.devices = res.data || []
                    this.loading = false;
                })
            } else {
                this.devices = [];
            }
        },

        handleEdit(row, index) {
        },

        handleRefund(row, index) {
            this.$confirm('此操作将会退还订单金额, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                orderRefund({
                    orderUuid: row.orderUuid,
                    deviceUuid: row.saleUuid,
                    fee: parseInt(row.money)
                }).then(() => {
                    this.$message.success('退款成功')
                    this.orders[index].payStatus = 2
                })
            }).catch(() => {

            })

        },
        handleDelete(row, index) {
        },
        handleAreaChange(value) {
            console.log(value)
        }
    },
}
</script>

<style lang="scss" scoped>
.orders {
    display: flex;
    flex-direction: column;

    .order-today-summary {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 80px;

        div {
            flex: 1;
            height: 100%;
            box-shadow: inset 0 0 1em rgba(0, 0, 0, 0.5);
        }
    }

    .order-data-list {
        margin-top: $padding;
        flex: 1;

        .el-table {
            thead {
                tr {
                    th {
                        border-bottom: 1px solid;
                        @include set_border_color($--border-color, $--border-color-dark);
                    }
                }
            }

        }

        .order {
            padding: 0 .3rem;
        }

        .order-edit,
        .order-delete {
            cursor: pointer;
            display: inline-block;

            i {
                margin-right: 2px;
            }
        }

        .order-edit:hover,
        .order-delete:hover {
            //color: $f_color;
        }

        .order-edit {
            color: $btn_color;
        }

        .order-edit:hover {}

        .order-delete {
            color: $btn_sec_color;
        }

        .order-delete:hover {}

        .disable {
            cursor: not-allowed;
            color: $--font-03-color;
            border: none;
        }

        .disable:hover {
            //    color: $f_50_color;
            //   background-color: $bg_six_color;
        }

        .circle {
            // border-radius: $small_font_size;
            // width: $small_font_size;
            // height: $small_font_size;
            display: inline-block;
            margin-right: 5px;
        }

        .wechatPay {
            color: #44b549;
        }

        .aliPay {
            color: #00aaee;
        }

        .testPayMake {
            color: #5AD8A6;
        }

        .notPayMake {
            color: #F6BD16;
        }

        .payMake {
            color: #5B8FF9;
        }

        .offline {
            color: #5B8FF9;

            .circle {
                background-color: #5B8FF9;
            }
        }

        .normal {
            color: #5AD8A6;

            .circle {
                background-color: #5AD8A6;
            }
        }

        .warning {
            color: #F6BD16;

            .circle {
                background-color: #F6BD16;
            }
        }

        .fault {
            color: #E8684A;

            .circle {
                background-color: #E8684A;
            }
        }

    }

    .pagination {
        margin: $padding;
        text-align: right;
    }
}
</style>
